import React, { useEffect, FC, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Globle } from "hooks/Globle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import $ from "jquery";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import rightImg from "images/about-hero-right.png";
import Banner from "components/SectionHero/Banner";
import StayDatesRangeInput from "components/StayDatesRangeInput";
import SectionDateRange from "components/SectionDateRange";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Checkbox from "shared/Checkbox/Checkbox";
import {
    CheckBadgeIcon,
    CheckCircleIcon,
    CheckIcon,
    PencilSquareIcon
} from "@heroicons/react/24/solid";
import { TruckIcon } from "@heroicons/react/24/outline";
import PalletSelectionBox from "components/PalletSelectionBox";

export interface CheckOutPageProps {
    className?: string;
}

interface dataType {
    collections: object;
    data: object;
    deliveries: object;
    disable_dates: Date[];
    express_delivery: number;
    greaterThenFivePm: number;
    is_unique_code: number;
    pallets: object;
    products: object;
    setting: object;
    standard_delivery: number;
}

interface ListType {
    collection_postcode: string;
    delivery_postcode: string;
    product: object;
    sessionId: string;
    type: number;
    weight: number;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
    const navigate = useNavigate();
    const [data, setData] = useState<dataType | undefined>(undefined);
    const [list, setList] = useState<ListType>(Object);
    const [delivery, setDelivery] = useState(1);
    const [product, setProduct] = useState([]);
    const [collectionDate, setCollectionDate] = useState<Date>(new Date());
    const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());
    const [deliveries, setDeliveries] = useState([]);
    const [collections, setCollections] = useState([]);
    const [selectedDeliverySlot, setSelectedDeliverySlot] = useState(Object);
    const [selectedCollection, setSelectedCollection] = useState([]);
    const [editWeight, setEditWeight] = useState(false);
    const [weight, setWeight] = useState<number>(0);
    const [palletWeight, setPalletWeight] = useState<number>(0);
    const [changedPallet, setChangedPallet] = useState<any>();

    const { apiPath , convertToObject } = Globle();
    const userSession = Cookies.get("userSession");

    const inputRef = useRef<any>(null);
    const [fetchPrice, setFetchPrice] = useState(false);
    const [triggerMakeDate, setTriggerMakeDate] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalDisPrice, setTotalDisPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [disPrice, setDisPrice] = useState(0);
    const [percent, setPercent] = useState(0);
    const [onlySaturday, setOnlySaturday] = useState(false);
    const [time, setTime] = useState("");
    const [slot, setSlot] = useState("")
    const [pauseSubmit, setPauseSubmit] = useState(true)
    const [greaterThenFivePm, setGreaterThenFivePm] = useState(false);
    const [standardDelivery, setStandardDelivery] = useState(2);
    const [urgentDelivery, setUrgentDelivery] = useState(1);
    
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setEditWeight(false);
            }
        };

        if (editWeight) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            runFinalPrice();
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [editWeight]);


    const loadCheckoutSession = () => {
        axios
            .get(apiPath + "api/checkout?sessionId=" + userSession)
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        data: any;
                        success: boolean;
                    };

                    if (
                        dataResp.success == false &&
                        typeof dataResp.errors != undefined
                    ) {
                        navigate("/");
                    }

                    if (dataResp.success == true) {
                        if(dataResp.data && dataResp.data.disable_dates){
                            const date = new Date();
                            const options:any = { month: 'short', day: '2-digit', year: 'numeric' };
                            const formattedDate = date.toLocaleDateString('en-US', options);
                            dataResp.data.disable_dates.push(formattedDate);
                        }
                        setData(dataResp.data);
                        setList(dataResp.data.data);
                        setProduct(dataResp.data.products);
                        changeOnPallet(dataResp.data.products);
                        setDeliveries(dataResp.data.deliveries);
                        setCollections(dataResp.data.collections);
                        setWeight(dataResp.data.data.weight);
                        setSlot('B-B');
                        setGreaterThenFivePm(dataResp.data.greaterThenFivePm == 1 ? true : false);
                        setStandardDelivery(dataResp.data.standard_delivery);
                        setUrgentDelivery(dataResp.data.express_delivery);
                    }
                } else {
                    toast.error("Something went wrong, Please Try Again!");
                }
            })
            .catch((error) => console.error("Error adding user:", error));
    };

    const getDeliveryData = () => {
        axios
            .post(apiPath + "api/get-deliveries-slots", {
                type: delivery,
                delivery_postcode: list.delivery_postcode,
                collection_postcode: list.collection_postcode,
                product: changedPallet,
            })
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        data: any;
                        success: boolean;
                    };

                    if (
                        dataResp.success == false &&
                        typeof dataResp.errors != undefined
                    ) {
                        // navigate("/");
                    }

                    if (dataResp.success == true) {
                        setDeliveries(dataResp.data);
                    }
                } else {
                    toast.error("Something went wrong, Please Try Again!");
                }
            })
            .catch((error) => console.error("Error adding user:", error));
    };

    const finalPrice = (force = false) => {
        if (fetchPrice === true || force === true) {
            setFetchPrice(false);
            setTimeout(() => {
                setFetchPrice(true);
            }, 1000);

            const pricingData = {
                sessionId: userSession,
                type: delivery,
                slot: slot,
                product: changedPallet,
                weight: weight,
            };

            axios
            .post(apiPath + "api/get-session-pricing", pricingData)
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        error: string;
                        data: any;
                        success: boolean;
                    };
                    if (dataResp.success == false) {
                        if (typeof dataResp.error != undefined) {
                            toast.error(dataResp.error);
                        }

                        if (dataResp.errors && dataResp.errors.length > 0) {
                            dataResp.errors.map((item: any) => {
                                toast.error(item);
                            });
                        }

                        setPrice(0);
                        setDisPrice(0);
                        setPercent(0);
                        setPauseSubmit(false);
                    }else if(weight <= palletWeight){
                        setPauseSubmit(true);
                    }

                    if (dataResp.success == true) {
                        const dd = dataResp.data;
                        setPrice(dd.price);
                        setDisPrice(dd.dis_price);
                        setPercent(dd.percent);
                        setCollections(dd.collections);

                        const updatedCollection = dd.collections.map((slot:any) => {
                            return {
                                name: slot.collection_option_name,
                                price: slot.collection_option_price,
                                id: slot.collection_option_id
                            }
                        });

                        if(JSON.stringify(updatedCollection) !== JSON.stringify(selectedCollection)){
                            setSelectedCollection([])
                            let boxes = $('.collectionBox').find('.collectionCheckBox');
                            if(boxes.length > 0){
                                $(boxes).each((k, v) => {
                                    $(v).find('input').prop('checked', false);
                                })
                            }
                        }
                    }
                } else {
                    toast.error("Something went wrong, Please Try Again!");
                }
            })
            .catch((error) => console.error("Error adding user:", error));
        }
    };

    useEffect(() => {
        loadCheckoutSession();
    }, []);

    useEffect(() => {
        if(list && list.delivery_postcode && list.collection_postcode && delivery && changedPallet){
            getDeliveryData();
        }
    }, [delivery]);

    useEffect(() => {
        if (selectedDeliverySlot && !selectedDeliverySlot.delivery_option_id) {
            if(onlySaturday){
                const slot:any = deliveries.filter((item:any) => {
                    return (item.tms_service_group == 'D' && item.tms_service_code == 'AM') ? item : [];
                })
                if(slot){
                    selectDelverySlot(slot.delivery_option_id);
                    $('[data-groupcode="D-AM"]').find('input').trigger('click');
                }
            }else{
                const slot: any = deliveries[0] ?? [];
                selectDelverySlot(slot.delivery_option_id ?? null);
            }
        }
        
    }, [deliveries]);


    useEffect(() => {
        runFinalPrice();
    }, [product, delivery, changedPallet]);

    useEffect(() => {
        calcTotal();
    }, [price, selectedDeliverySlot, selectedCollection]);

    useEffect(() => {
        if(palletWeight && weight && weight > palletWeight){
            setPauseSubmit(false);
        }else{
            setPauseSubmit(true);
        }
    }, [palletWeight, weight, changedPallet])

    const changeType = (type: number) => {
        if(!onlySaturday){
            setDelivery(type);
        }
    };

    const runFinalPrice = () => {
        if (
            changedPallet &&
            typeof changedPallet.MQP != undefined &&
            product &&
            product.length > 0 &&
            delivery && 
            slot != "" && slot != null
        ) {
            finalPrice(true);
            setTriggerMakeDate(true);
        }
    }

    const changeOnPallet = (product: any) => {
        let palletQtys: any = {};
        const p = product.map((item: any) => {
            return (item.qty != 0) ? (palletQtys[item.api_product_code] = parseInt(item.qty)) : '';
        });
        const totalWeight = product.reduce((wgts: number, item: any) => {
            return wgts + (item.qty > 0 ? item.weight * item.qty : 0);
        }, 0);
        setPalletWeight(totalWeight);
        setChangedPallet(palletQtys);
    };

    const changeDates = (collectionDate: Date, deliveryDate: Date) => {
        setCollectionDate(collectionDate);
        setDeliveryDate(deliveryDate);
    };

    const formatDate = (date:any) => {
        const formattedDate = date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0');
        return formattedDate
    }

    const selectDelverySlot = (id: number) => {
        const slot: any = deliveries.find((item: any) => {
            return id === item.delivery_option_id;
        });
        if (slot) {
            if(slot.tms_service_code == 'AM' && slot.tms_service_group == 'D'){
                setOnlySaturday(true);
                setDelivery(2);
            }else{
                setOnlySaturday(false);
            }
            setSelectedDeliverySlot({
                name: slot.delivery_option_name,
                id: slot.delivery_option_id,
                price: slot.delivery_option_price,
                slot: slot.tms_service_group + "-" + slot.tms_service_code
            });
        }
    };

    const selectCollection = (id: number) => {
        const slot: any = collections.find(
            (item: any) => id === item.collection_option_id
        );
        if (slot) {
            setSelectedCollection((prevSelected: any) => {
                const isAlreadySelected = prevSelected.some(
                    (selectedItem: any) => selectedItem.id === id
                );

                if (isAlreadySelected) {
                    return prevSelected.filter(
                        (selectedItem: any) => selectedItem.id !== id
                    );
                } else {
                    return [
                        ...prevSelected,
                        {
                            name: slot.collection_option_name,
                            price: slot.collection_option_price,
                            id: slot.collection_option_id
                        }
                    ];
                }
            });
        }
    };

    const calcTotal = () => {
        let currentPrice: number = 0;
        currentPrice += +selectedDeliverySlot.price;
        currentPrice += +selectedCollection.reduce((sum: number, item: any) => {
            return sum + item.price;
        }, 0);
        currentPrice += price;
        setTotalPrice(currentPrice);
        currentPrice -= price;
        currentPrice += disPrice;
        setTotalDisPrice(currentPrice);
    };

    const handleErrors = (errors: { [key: string]: string[] }) => {
        Object.keys(errors).forEach(key => {
            const errorMessages = errors[key];
            errorMessages.forEach(message => {
            toast.error(message);
            });
        });
    };

    const handleSubmit = async (event:any) => {
        event.preventDefault();

        $(event.target).find('input[name="sessionId"]').val(userSession ?? '');
        const inputArray = $(event.target).serializeArray();
        const data = convertToObject(inputArray);
    
        await axios.post(apiPath+'api/store-checkout-address', data).then(response => {
    
          if(response.status == 200){
            const dataResp = response.data as {errors: any;success: boolean, error:any};
            
            if(dataResp.success == false && typeof dataResp.errors != undefined && Array.isArray(dataResp.errors)){
                dataResp.errors.map((item: string) => toast.error(item));
            }else if(dataResp.success == false && typeof dataResp.errors != undefined){
              handleErrors(dataResp.errors);
            }else if(dataResp.success == false && dataResp.error){
                toast.error(dataResp.error);
            }
    
            if(dataResp.success == true){
              navigate('/checkout-address');
            }
    
          }else{
            toast.error("Something went wrong, Please Try Again!");
          }
    
        }).catch(error => console.error('Error adding user:', error));
    }

    const renderSidebar = () => {
        return (
            <div className="listingSectionSidebar__wrap shadow-xl p-6">
                {/* PRICE */}
                <div className="flex justify-between">
                    <div className="text-4xl font-semibold">
                        £{(totalPrice) ? totalPrice.toFixed(2) : 0}
                        {disPrice && disPrice > 0 ? (
                            <span className="block text-base font-normal text-neutral-500 dark:text-neutral-400">
                                Was: £{totalDisPrice.toFixed(2)} ( <b>{percent}%</b> OFF! )
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="text-right">
                        <span className={(weight > palletWeight) ? `block text-base font-normal text-red-800 text-neutral-500 dark:text-neutral-400` : `block text-base font-normal text-neutral-500 dark:text-neutral-400`}>
                            Weight (KGs)s.
                        </span>
                        <div className="text-2xl font-semibold">
                            {editWeight ? (
                                <input
                                    type="number"
                                    value={weight ?? ""}
                                    onChange={(e: any) =>
                                        setWeight(e.target?.value || null)
                                    }
                                    className="w-28 inline mr-1"
                                    style={{ fontSize: "19px" }}
                                    ref={inputRef}
                                />
                            ) : (
                                <span>
                                    <PencilSquareIcon
                                        className="w-5 h-5 inline cursor-pointer text-base text-neutral-500 dark:text-neutral-400"
                                        onClick={() => {
                                            setEditWeight(true);
                                        }}
                                    />{" "}
                                    {weight}
                                </span>
                            )}
                            kg
                        </div>
                    </div>
                </div>

                {/* FORM */}
                <div className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
                    <StayDatesRangeInput
                        className="flex-1 z-[11]"
                        collectionDate={collectionDate}
                        deliveryDate={deliveryDate}
                    />

                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>

                    <div className="flex justify-between">
                        <div
                            className="flex relative cursor-pointer"
                            style={{ maxWidth: "50%", flex: "0 0 50%" }}
                            onClick={() => changeType(1)}
                        >
                            <div className="flex-1 z-10 flex items-center focus:outline-none nc-flex-1">
                                <div className="relative z-10 flex-1 flex text-left items-center px-4 py-3 space-x-3 focus:outline-none w-100">
                                    {delivery == 1 ? (
                                        <div className="text-300 dark:text-400">
                                            <CheckCircleIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                                        </div>
                                    ) : (
                                        <div className="text-neutral-300 dark:text-neutral-400">
                                            <TruckIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                                        </div>
                                    )}
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex-grow">
                                            <span className="block xl:text-lg font-semibold">
                                                Standard
                                            </span>
                                            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                                                Delivery
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="flex relative cursor-pointer w-50"
                            style={{ maxWidth: "50%", flex: "0 0 50%" }}
                            onClick={() => changeType(2)}
                        >
                            <div className="flex-1 z-10 flex items-center focus:outline-none nc-flex-1">
                                <div className="relative z-10 flex-1 flex text-left items-center px-4 py-3 space-x-3 focus:outline-none w-100">
                                    {delivery == 2 ? (
                                        <div className="text-300 dark:text-400">
                                            <CheckCircleIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                                        </div>
                                    ) : (
                                        <div className="text-neutral-300 dark:text-neutral-400">
                                            <TruckIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                                        </div>
                                    )}
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex-grow">
                                            <span className="block xl:text-lg font-semibold">
                                                Urgent
                                            </span>
                                            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                                                Delivery
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* SUM */}
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <h2 className="text-1xl font-semibold">Pallet Rate</h2>
                        <span className="font-semibold">
                            +£{price.toFixed(2)}
                        </span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                    {selectedDeliverySlot &&
                    selectedDeliverySlot.id != undefined ? (
                        <>
                            <h2 className="text-1xl font-semibold">Delivery</h2>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                <span>{selectedDeliverySlot.name}</span>
                                {selectedDeliverySlot.price > 0 ? (
                                    <span className="font-semibold">
                                        +£{selectedDeliverySlot.price.toFixed(2)}
                                    </span>
                                ) : (
                                    <span className="font-semibold">Free</span>
                                )}
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    {selectedCollection && selectedCollection.length > 0 ? (
                        <>
                            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                            <h2 className="text-1xl font-semibold">
                                Optional Service
                            </h2>
                        </>
                    ) : (
                        ""
                    )}

                    {selectedCollection && selectedCollection.length > 0
                        ? selectedCollection.map((item: any, index: number) => (
                              <div
                                  key={'12'+index + item.id}
                                  className="flex justify-between text-neutral-6000 dark:text-neutral-300"
                              >
                                  <span>{item.name}</span>
                                  {item.price > 0 ? (
                                      <span className="font-semibold">
                                          +£{item.price.toFixed(2)}
                                      </span>
                                  ) : (
                                      <span className="font-semibold">
                                          Free
                                      </span>
                                  )}
                              </div>
                          ))
                        : ""}

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total ({percent}% OFF)</span>
                        <span>
                            <del className="text-1x1 font-semibold text-red-800" style={{fontSize:'13px'}}>£{disPrice.toFixed(2)}</del> £{totalPrice.toFixed(2)}
                        </span>
                    </div>
                </div>

                <form onSubmit={handleSubmit} method="POST" className="w-full block">
                    <input type="hidden" name="sessionId" />
                    <input type="hidden" value={totalPrice ? totalPrice : 0} name="price" />
                    <input type="hidden" value={delivery ? delivery : ''} name="type" />
                    <input type="hidden" value={list.collection_postcode ? list.collection_postcode : ''} name="collection_postcode" />
                    <input type="hidden" value={list.delivery_postcode ? list.delivery_postcode : ''} name="delivery_postcode" />
                    <input type="hidden" value={collectionDate && collectionDate.toISOString() ? formatDate(collectionDate) : ''} name="collection_date" />
                    <input type="hidden" value={deliveryDate && deliveryDate.toISOString() ? formatDate(deliveryDate) : ''} name="delivery_date" />
                    <input type="hidden" value={weight ? weight : 0} name="weight" />
                    <input type="hidden" value={changedPallet ? JSON.stringify( changedPallet ) : ''} name="product" />
                    <input type="hidden" value={selectedCollection ? JSON.stringify(selectedCollection.map((item:any) => {return item.id})) : ''} name="collection" />
                    <input type="hidden" value={selectedDeliverySlot && selectedDeliverySlot.slot ? selectedDeliverySlot.slot : ''} name="slot" />
                    <input type="hidden" value={time ? time : ''} name="time" />

                    <button type={(pauseSubmit==false) ? 'button': 'submit'} className={`w-full ttnc-ButtonPrimary disabled:bg-opacity-70 ${(pauseSubmit==false) ? `bg-neutral-800 hover:bg-neutral-300` : `bg-primary-6000 hover:bg-primary-700`}  text-neutral-50 tnc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors  px-4 py-3 sm:px-6 text-sm sm:text-base font-medium`}> 
                        {(weight > palletWeight) ? (
                            <span >
                                Over Max Weight by <b>{weight - palletWeight}KG</b> 
                            </span>
                        ) : (
                            (pauseSubmit==false) ? (
                                <span>Update Weight</span>
                            ) : (
                                <span>Continue</span>
                            )
                        )}
                    </button>
                </form>

            </div>
        );
    };

    const deliverySlot = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Delivery Slot </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        Delivery times and prices may vary depending on the
                        postal code.
                    </span>
                </div>
                <div className="flow-root">
                    <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
                        {deliveries != undefined && deliveries.length > 0 ? (
                            deliveries.map((item: any, index: number) => (
                                <div
                                    key={"0" + index + item.delivery_option_id}
                                    className={
                                        index % 2 == 0
                                            ? `bg-neutral-100 p-4 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg`
                                            : `p-4 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg`
                                    }
                                >
                                    <div className="flex justify-between flex-col" data-groupcode={item.tms_service_group + "-" + item.tms_service_code}>
                                        <Checkbox
                                            type="radio"
                                            label={
                                                item.delivery_option_name +
                                                " " +
                                                item.delivery_option_description
                                            }
                                            nameId={
                                                "delivery_slot_" +
                                                item.delivery_option_id
                                            }
                                            name="delivery_slot"
                                            defaultChecked={
                                                index == 0 ? true : false
                                            }
                                            onChange={() => {
                                                selectDelverySlot(
                                                    item.delivery_option_id
                                                );
                                            }}
                                        />
                                        {(item.has_time && selectedDeliverySlot.id == item.delivery_option_id) ? (
                                            <div className="mt-3">
                                                <input type="time" name="time" onChange={e => setTime(e.target.value)} />
                                            </div>
                                        ) : ('')}
                                    </div>
                                    <span>
                                        {item.delivery_option_price == 0
                                            ? "Free"
                                            : "+£" + item.delivery_option_price}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <p>No deliveries slot available.</p> // Fallback content if no deliveries
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const optionals = () => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">
                        Optional Services
                    </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        Enhance your pallet booking with a range of optional
                        services. Choose additional features tailored to meet
                        your specific needs.
                    </span>
                </div>
                <div className="flow-root">
                    <div className="collectionBox text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
                        {collections && collections.length > 0 ? (
                            collections.map((item: any, index: number) => (
                                <div
                                    key={ +index + 999 }
                                    className={
                                        index % 2 == 0
                                            ? `bg-neutral-100 p-4 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg`
                                            : `p-4 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg`
                                    }
                                >
                                    <Checkbox
                                        type="checkbox"
                                        className="collectionCheckBox"
                                        label={
                                            item.collection_option_name +
                                            " " +
                                            item.collection_option_description
                                        }
                                        nameId={
                                            "collections_" +
                                            item.collection_option_id
                                        }
                                        name={
                                            `collections[` + item.api_name + `]`
                                        }
                                        onChange={() => {
                                            selectCollection(
                                                item.collection_option_id
                                            );
                                        }}
                                    />
                                    <span>
                                        {item.collection_option_price == 0
                                            ? "Free"
                                            : "+£" +
                                              item.collection_option_price}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <p>No collections available.</p> // Fallback content if no deliveries
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const palletLists = (data: any) => {
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">
                        Change Selected Pallets
                    </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        Enhance your pallet booking with a range of optional
                        services. Choose additional features tailored to meet
                        your specific needs.
                    </span>
                </div>
                <div className="flow-root">
                    <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
                        <PalletSelectionBox
                            products={product}
                            className="mb-2"
                            changeOnPallet={changeOnPallet}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div
                className={`nc-PageAbout overflow-hidden relative hidden ${className}`}
                data-nc-id="CheckOut"
            >
                <Helmet>
                    <title>Checkout | PalletBooking</title>
                </Helmet>

                {/* ======== BG GLASS ======== */}
                <BgGlassmorphism />

                <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                    <Banner
                        rightImg={rightImg}
                        heading="👋 CheckOut"
                        btnText=""
                        subHeading="We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
                    />
                </div>
            </div>

            <div className="ListingDetailPage">
                <div className="container ListingDetailPage__content">
                    <div className="nc-ListingStayDetailPage">
                        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
                            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                                <SectionDateRange
                                    collectionCode={
                                        list ? list.collection_postcode : ""
                                    }
                                    deliveryCode={
                                        list ? list.delivery_postcode : ""
                                    }
                                    disabledDates={
                                        data && data.disable_dates
                                            ? data.disable_dates
                                            : []
                                    }
                                    type={delivery}
                                    addDays={delivery == 1 ? standardDelivery : urgentDelivery}
                                    changeDates={changeDates}
                                    triggerMakeDate={triggerMakeDate}
                                    onlySaturday={onlySaturday}
                                    weight={weight}
                                    palletWeight={palletWeight}
                                    greaterThenFivePm={greaterThenFivePm}
                                />
                                {deliverySlot()}
                                {optionals()}
                                {palletLists(data)}
                            </div>

                            <div className="lg:block flex-grow mt-14 lg:mt-0">
                                <div className="sticky top-28">
                                    {renderSidebar()}
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

                <div className="container py-6 lg:py-8 space-y-6 lg:space-y-8">
                    {/* <SectionSubscribe2 className="pt-24" /> */}
                </div>
            </div>
        </>
    );
};

export default CheckOutPage;
