import { useState, useEffect } from "react";
import { Globle } from 'hooks/Globle';


export interface Pallet {
    product_id: number;
    product_name: string;
    oversize: number;
    weight: number;
    api_product_code: string;
    description_line1: string;
    description_line2: string;
    status: boolean;
    qty?: number;
}


export const usePallets = () => {
    const [pallets, setPallets] = useState<Pallet[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<null | string>(null);
    const { apiPath } = Globle();


    useEffect(() => {
        const fetchPallets = async () => {
            try {
                
                const response = await fetch(apiPath+"api/products");
                const data = await response.json();
                setPallets(data.data);
                console.log('loading');

            } catch (err) {
                setError("Error fetching pallets");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPallets();
    }, []);

    return { pallets, loading, error };
};