import React from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Banner from "components/SectionHero/Banner";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "shared/NcImage/NcImage";
import rightImg from "images/BecomeAnAuthorImg.png";
import LogoFav from "shared/Logo/LogoFav";
import Heading from "components/Heading/Heading";

const WhyUsPage: React.FC = () => (
    <div className={`nc-PayPage`} data-nc-id="PayPage">
        <div
            className={`nc-PageAbout overflow-hidden relative`}
            data-nc-id="CheckOut"
        >
            <Helmet>
                <title>Why Us | PalletBooking</title>

                <meta property="og:title" content="Why Choose Pallet Booking?" />
                <meta property="og:description" content="We provide unbeatable convenience and reliability. Book your pallet delivery anytime, anywhere with our simple online system, ensuring a smooth and stress-free experience." />
                <meta property="og:image" content="/images/logo-site.png" />
                <meta property="og:url" content="/why-us" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="PalletBooking" />
                <meta property="og:locale" content="en_US" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Why Choose Pallet Booking?" />
                <meta name="twitter:description" content="Enjoy hassle-free and reliable pallet delivery. Our user-friendly online system lets you book from the comfort of your home or office, anytime." />
                <meta name="twitter:image" content="/images/logo-site.png" />

            </Helmet>

            {/* ======== BG GLASS ======== */}
            {/* <BgGlassmorphism /> */}

            <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Why Us
            </h2>
        </div>

        <main className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
            <div
                className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center py-12`}
                data-nc-id="SectionBecomeAnAuthor"
                >
                <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
                    {/* <LogoFav className={`w-20`} /> */}
                    <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
                    Why did you choose us?
                    </h2>
                    <p className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                    First and foremost, we offer convenience and reliability. With our easy-to-use online booking system, you can book your pallet delivery from the comfort of your own home or office, at any time of the day.
                    </p>

                    <p className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                    Our system is designed to provide you with instant quotes and allow you to track your deliveries in real time. This saves you the hassle of having to call and wait for quotes or updates. We understand that businesses value speed and efficiency, which is why we have streamlined our booking process to ensure a quick and hassle-free experience.
                    </p>
                </div>
                <div className="flex-grow">
                    <NcImage src={rightImg} />
                </div>
            </div>

            <div className={`nc-Banner relative`} data-nc-id="Banner">
                {/* <BgGlassmorphism /> */}
                <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
                    <div className={`w-screen max-w-full space-y-5 lg:space-y-7`}>
                        <h2 className={`text-2xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-4xl dark:text-neutral-100`} >
                            Another reason to choose Palletbooking
                        </h2>
                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            Moreover, we have a nationwide coverage, which means we can collect and deliver to any destination in the UK and Ireland. Our extensive network allows us to offer a door-to-door delivery service, making it even more convenient for you. You no longer have to worry about arranging for transportation to and from depots. We do all the heavy lifting for you, so you can focus on other important aspects of your business.
                        </span>
                        
                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            Another reason to choose Palletbooking is our competitive pricing. Our prices are transparent and there are no hidden fees. With our large customer base, we are able to negotiate the best rates from our network of logistics partners, and we pass these savings on to our customers. We also offer discounts for bulk shipments, making it more affordable for businesses that require frequent deliveries.
                        </span>

                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            Our commitment to customer service is another reason why we are the top choice for pallet booking. Our friendly and knowledgeable team is always ready to assist you with any queries or concerns you may have. We strive to provide the best customer experience and ensure complete satisfaction with our services.
                        </span>

                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            At Palletbooking, we also prioritize the safety and security of your goods. We understand that pallets can be fragile and require special handling. That's why we have a team of trained professionals who handle your goods with care. We also offer insurance options, so you can have peace of mind knowing that your goods are protected during delivery.
                        </span>

                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            Last but not least, we are an environmentally friendly company. We are committed to reducing our carbon footprint and have implemented various measures to achieve this. We use optimised delivery routes to reduce fuel consumption and emissions, and also offer carbon offsetting options for those who want to further support our efforts.
                        </span>

                        <span className={`block mt-5 text-neutral-500 dark:text-neutral-400`}>
                            In conclusion, with Palletbooking, you can expect convenience, reliability, competitive pricing, excellent customer service, and a commitment to environmental sustainability. We have earned the trust of numerous businesses and individuals through our years of experience in the industry. So why settle for less when you can choose Palletbooking for all your pallet booking needs? Book with us today and experience the difference!
                        </span>
                    </div>
                </div>
            </div>

        </main>

        <div className="container py-6">
            {/* <SectionSubscribe2 className="" /> */}
        </div>
    </div>
);

export default WhyUsPage;
