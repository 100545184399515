import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/15709.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
  handleSubmit: (event: any) => void;
  trackingSubmit: (event: any) => void;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "", handleSubmit, trackingSubmit }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-50 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl !leading-[114%] ">
            Seamless Pallet Delivery,
            Every Time
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
          Use Pallet Booking to find the most competitive rates for your pallet delivery needs. Our online platform makes it easy to compare prices and choose the best option for your business. Enjoy cost-effective, reliable, and efficient pallet delivery services with just a few clicks. Start saving today!
          </span>
          {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <img className="w-2/3" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm handleSubmit={handleSubmit} trackingSubmit={trackingSubmit} />
      </div>
    </div>
  );
};

export default SectionHero;
