import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

interface SearchState {
  pallet: string | null;
  collect: string | null;
  deliver: string | null;
}

export const useMakeSearch = (initialSearch?: SearchState) => {
  const [palletSearch, setPalletSearch] = useState<SearchState>({
    pallet: null,
    collect: null,
    deliver: null,
  });

  useEffect(() => {

    const userSearch = Cookies.get('userSearch');
    
    if (userSearch) {
      const parsedSearch = JSON.parse(userSearch);
      setPalletSearch(parsedSearch);
    } else if (initialSearch) {
      setPalletSearch(initialSearch);
    }
    
  }, [initialSearch]);

  return { palletSearch, setPalletSearch };
};
