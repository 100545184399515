import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Banner from "components/SectionHero/Banner";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import $ from 'jquery';
import { useLocation } from 'react-router-dom';

const TermsOfUsePage: React.FC = () => {
    
    const location = useLocation();

    const moveTerms = (event:any) => {
        var hash:any = $(event.target).attr('href');
        var target:any = $(hash);
        $('html, body').animate({
            scrollTop: target.offset().top - 85
        }, 1000);
    }

    useEffect(() => {
        // Function to scroll to the section based on hash
        const scrollToHash = () => {
          const hash = window.location.hash; // Get the hash from the URL
          if (hash) {
            var target:any = $(hash);
            $('html, body').animate({
                scrollTop: target.offset().top - 85
            }, 1000);
          }
        };
    
        // Call the scroll function when the component mounts or when location changes
        scrollToHash();
      }, [location]);

    const renderSidebar = () => {
        return (
            <div className=" w-full flex flex-col items-start text-left sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                {/* ---- */}
                <div className="space-y-3 text-center flex flex-col items-start">
                    <h2 className="text-3xl font-semibold">Table of Contents</h2>
                </div>

                {/* ---- */}
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-2 text-sm text-neutral-700 dark:text-neutral-300 ">
                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Introduction">Introduction</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Definitions">Definitions</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Privacy">Privacy Policy</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Services">Services</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Orders">Orders</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Payment">Payment</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Delivery-and-Collection">Delivery and Collection</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Packaging-and-Labeling">Packaging and Labeling</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Liability">Liability</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Cancellation-and-Refunds">Cancellation and Refunds</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Compliance-with-Laws">Compliance with Laws</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Governing-Law">Governing Law</a></span>
                    </div>

                    <div className="flex items-center space-x-3">
                        <i className="las la-check-circle text-2xl"></i>
                        <span><a onClick={moveTerms} href="#Contact-Information">Contact Information</a></span>
                    </div>
                </div>

            </div>
        );
    };

    const renderSection1 = () => {
        return (
            <>
                <style>
                {`
                    .terms_n p {
                        font-size: 14px;
                        color: #374151;
                        padding-bottom: 8px;
                        line-height: 20px;
                    }
                    .terms_n strong {
                        font-size: 18px;
                        padding: 10px 0px !important;
                        display: block;
                    }
                `}
                </style>
                <div className="listingSection__wrap">
                    <div className="terms_n">
                        <p id="Introduction">
                            <strong>
                                <span>01 Introduction</span>
                            </strong>
                        </p>
                        <ul>
                            <li>These Terms and Conditions govern the use of our pallet delivery services. By using our services, you agree to be bound by these terms. Please read them carefully.</li>
                        </ul>
                
            
                        <p id="Definitions">
                            <strong>
                                <span>02 Definitions</span>
                            </strong>
                        </p>
                        <p>
                            <span>Company: Refers to Pallet Booking, a division of Goodwill Logistics Limited, registered number 10732900.</span>
                        </p>
                        <p>
                            <span>Customer: Any individual or business using our pallet delivery services.</span>
                        </p>
                        <div>
                            <ul>
                                <li>
                                    <strong>
                                        <span>Goods: Items being transported on pallets.</span>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <span>Pallet: A flat transport structure that supports goods in a stable manner.</span>
                                    </strong>
                                </li>
                            </ul>
                        </div>

                        <p id="Privacy">
                            <strong>
                                <span>03 Privacy Policy</span>
                            </strong>
                        </p>
                        <p>
                            <span>We are committed to protecting your privacy. This policy outlines how we collect, use, and protect your personal information.</span>
                        </p>
                        <p>
                            <span> Data Collection: We collect personal data such as your name, address, contact details, and payment information when you place an order or contact us.</span>
                        </p>
                        <p>
                            <span>Use of Data: Your personal data is used to process orders, manage your account, and communicate with you regarding our services.</span>
                        </p>
                        <p>
                            <span>Data Sharing: We do not share your personal data with third parties, except as necessary to provide our services or as required by law.</span>
                        </p>
                        <p>Your Rights: You have the right to access, correct, or delete your personal data. You can contact us at sales@palletbooking.co.uk to exercise these rights.</p>
                        <p>
                            <span> Cookies: Our website uses cookies to enhance your browsing experience. You can disable cookies through your browser settings, but this may affect the functionality of our website.</span>
                        </p>
            
                        <p id="Services">
                            <strong>
                                <span>04 Services</span>
                            </strong>
                        </p>
                        <p>
                            <span>We provide pallet delivery services within the UK, including collection, transportation, and delivery of goods.</span>
                        </p>
            
                        <p id="Orders">
                            <strong>
                                <span>05 Orders</span>
                            </strong>
                        </p>
                        <p>
                            <span>Placing Orders: Orders can be placed through our website or customer service.
                            </span>
                        </p>
                        <p>
                            <span>Order Confirmation: An order is confirmed once you receive a confirmation email from us.</span>
                        </p>
                        <p>
                            <span>Changes to Orders: Any changes to orders must be communicated promptly.</span>
                        </p>
        
                        <p id="Payment">
                            <strong>
                                <span>06 Payment</span>
                            </strong>
                        </p>
                        <p>
                            <span>Pricing: Prices are based on the size, weight, and destination of the pallet.</span>
                        </p>
                        <p>
                            <span>Payment Terms: Payment must be made in full before the delivery service is provided..</span>
                        </p>
                        <p>
                            <span>Methods of Payment: We accept credit cards, debit cards, and bank transfers.</span>
                        </p>
                        
                        <p id="Delivery-and-Collection">
                            <strong>
                                <span>07 Delivery and Collection</span>
                            </strong>
                        </p>
                        <p>
                            <span>Collection: We will collect the goods from the address provided by the customer.</span>
                        </p>
                        <div>
                            <ul>
                                <li>
                                    <strong>
                                        <span> Delivery: Goods will be delivered to the specified address within the agreed timeframe.</span>
                                    </strong>
                                </li>
                                <li>
                                    <strong>
                                        <span>Delays: We are not responsible for delays caused by unforeseen circumstances.</span>
                                    </strong>
                                </li>
                            </ul>
                        </div>
                        
                        <p id="Packaging-and-Labeling">
                            <strong>
                                <span>08 Packaging and Labeling</span>
                            </strong>
                        </p>
                        <p>
                            <span> Packaging: Goods must be securely packaged to prevent damage during transit.</span>
                        </p>
                        <p>
                            <span>Labeling: Each pallet must be clearly labeled with the destination address and any special handling instructions.</span>
                        </p>

                        <p id="Liability">
                            <strong>
                                <span>09 Liability</span>
                            </strong>
                        </p>
                        <p>
                            <span>Damage or Loss: We are liable for any damage or loss of goods during transit, up to a maximum value of £5.00 per kilogram.</span>
                        </p>
                        <p>
                            <span>Exclusions: We are not liable for damage caused by inadequate packaging or labeling by the customer.</span>
                        </p>

                        <p id="Cancellation-and-Refunds">
                            <strong>
                                <span>10 Cancellation and Refunds</span>
                            </strong>
                        </p>
                        <p>
                            <span> Cancellation: Orders can be canceled up to 24 hours before the scheduled collection time.</span>
                        </p>
                        <p>
                            <span>Refunds: Refunds will be processed within 14 days of cancellation.</span>
                        </p>

                        <p id="Compliance-with-Laws">
                            <strong>
                                <span>11 Compliance with Laws</span>
                            </strong>
                        </p>
                        <p>
                            <span>Customers must ensure that their goods comply with all relevant laws and regulations.</span>
                        </p>
                
                        <p id="Governing-Law">
                            <strong>
                                <span>12 Governing Law</span>
                            </strong>
                        </p>
                        <p>
                            <span>These terms are governed by the laws of England and Wales. Any disputes will be resolved in the courts of England and Wales</span>
                        </p>
            
                        <p id="Contact-Information">
                            <strong>
                                <span>13 Contact Information</span>
                            </strong>
                        </p>
                        <p>
                            <span>For any queries or complaints, please contact our customer service team at:</span>
                        </p>
                        <p>
                            <span>Address: Unit D, Knaves Beech Industrial Estate, Knaves Beech Way, Loudwater, High Wycombe, England, HP10 9QY.</span>
                        </p>
                        <p>Website: <a href="https://www.palletbooking.co.uk" target="_blank">www.palletbooking.co.uk</a></p>
                        <p>Contact No: <a href="tel:+441753525959">+44 (0) 1753525959</a></p>
                        <p>Email: <a href="mailto:sales@palletbooking.co.uk">sales@palletbooking.co.uk</a></p>

                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={`nc-PayPage`} data-nc-id="PayPage">
            <div
                className={`nc-PageAbout overflow-hidden relative`}
                data-nc-id="CheckOut"
            >
                <Helmet>
                    <title>Terms And Condition | PalletBooking</title>

                    <meta property="og:title" content="Pallet Booking Terms and Conditions" />
                    <meta property="og:description" content="Review Pallet Booking's terms and conditions to understand the guidelines and policies governing our services." />
                    <meta property="og:image" content="/images/logo-site.png" />
                    <meta property="og:url" content="/terms-of-use" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="PalletBooking" />
                    <meta property="og:locale" content="en_US" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Pallet Booking Terms and Conditions" />
                    <meta name="twitter:description" content="Learn about our terms and conditions to ensure you fully understand our pallet delivery services and usage policies." />
                    <meta name="twitter:image" content="/images/logo-site.png" />

                </Helmet>

                {/* ======== BG GLASS ======== */}
                {/* <BgGlassmorphism /> */}

                <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                    <Banner
                        textClass="text-center"
                        heading="Terms And Condition"
                        btnText=""
                        subHeading="By using or accessing this Website, placing an order and/or registering as a member of the Website the Customer agrees to be bound by the Conditions set out below. Should the Customer have any queries in relation to the Conditions, the Customer should contact the Company's customer service department via Live Chat during business hours."
                    />
                </div>
            </div>

            <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
                <div className="block flex-grow mb-24 lg:mb-0">
                    <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
                </div>
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                    {renderSection1()}
                </div>
            </main>

            <div className="container py-6">
                {/* <SectionSubscribe2 className="" /> */}
            </div>
        </div>
    );
};

export default TermsOfUsePage;
