import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useMakeSearch } from 'hooks/makeSearch';

interface KeyValue {
    name: string;
    value: string;
}
interface GlobalState {
    user: string | null;
    setUser: (user: string | null) => void;
}

export const Globle = () => {
    if (window.location.hostname === "localhost") {
        var apiPath = "https://palletbooking.test/";
    } else {
        var apiPath = window.location.origin+"/api/";
    }
    

    const convertToObject = (arr: KeyValue[]): any => {
        return arr.reduce((acc: any, { name, value }: KeyValue) => {
            // Split the key into parts
            const parts = name.match(/(.*?)\[(.*?)\]/) || [name];
            const key = parts[1] || name;
            const subKey = parts[2] || null;

            if (subKey) {
                // Handle nested keys (e.g., product[KEY])
                if (!acc[key]) acc[key] = {};
                acc[key][subKey] = value;
            } else {
                // Handle regular keys
                acc[key] = value;
            }

            return acc;
        }, {});
    };

    return { apiPath, convertToObject };
};

export const HandleErrors = (errors: { [key: string]: string[] }) => {
    Object.keys(errors).forEach(key => {
        const errorMessages = errors[key];
        errorMessages.forEach(message => {
            toast.error(message);
        });
    });
};

export const usePalletSubmit = () => {
    const navigate = useNavigate();
    const { apiPath, convertToObject } = Globle();
    const { setPalletSearch } = useMakeSearch();

    const HandleSubmit = (event:any) => {
        event.preventDefault();
        const userSession = Cookies.get('userSession');

        $(event.target).find('input[name="sessionId"]').val(userSession ?? '');
        const inputArray = $(event.target).serializeArray();
        var data = convertToObject(inputArray);
        data.collection_postcode = data.collection_postcode.replace(/\s/g, '');
        data.delivery_postcode = data.delivery_postcode.replace(/\s/g, '');

        axios.post(apiPath+'api/store-checkout', data).then(response => {

        if(response.status == 200){
            const dataResp = response.data as {errors: any;success: boolean};
            
            if(dataResp.success == false && typeof dataResp.errors != undefined){
                HandleErrors(dataResp.errors);
            }

            if(dataResp.success == true){
                $('.closeMSearch').trigger('click');
                
                const search = {
                    collect: data.collection_postcode,
                    deliver: data.delivery_postcode,
                    pallet: data.pallet
                };

                setPalletSearch(search)

                Cookies.set('userSearch', JSON.stringify(search));
                
                navigate('checkout');
            }

        }else{
            toast.error("Something went wrong, Please Try Again!");
        }


        }).catch(error => console.error('Error adding user:', error));
    }
    
    return {HandleSubmit}
}

export const useTrackingSubmit = () => {
    const navigate = useNavigate();
    
    const TrackingSubmit = async (event:any) => {
        event.preventDefault();
        let tracking_code = $(event.target).find('[name="track"]').val();
        navigate(`/tracking/${tracking_code}`);
    }
    return {TrackingSubmit}
}

export const FetchSessionData = async () => {
    const { apiPath } = Globle();
    try {
        const response:any = await axios.get(apiPath+'api/session');
        Cookies.set('userSession', response.data);
    } catch (err) {
        toast.error("Something went wrong, Please Try Again!");
    } finally {

    }
};
