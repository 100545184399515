import React from 'react'
import rightImgPng from "images/pallet.png";
import NcImage from "shared/NcImage/NcImage";
export default function SectionDeliveryServices() {
  return (
    <>
    <div className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center py-0`}>
        
      <div className="absolute bg-opacity-40 bg-primary-100 dark:bg-neutral-800 dark:bg-opacity-40 h-full left-0 md:bottom-0 rounded-3xl sm:rounded-[50px] w-2/3 z-0"></div>
            <div className="flex-shrink-0 lg:mb-0 lg:w-3/5 mb-16 pl-20 pr-11 relative z-10">
                <h2 className="font-semibold text-3xl sm:text-4xl">
                    When to book PalletBooking delivery service?
                </h2>
                <ul>
                <li className={`flex items-center mt-5 text-neutral-500 dark:text-neutral-400`}>
                    <svg className="h-5 w-5 text-primary-6000 me-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    When items are too large or heavy to fit into standard packages.
                </li>

                <li className={`flex items-center mt-5 text-neutral-500 dark:text-neutral-400`}>
                    <svg className="h-5 w-5 text-primary-6000 me-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    If you have multiple parcels or items that need to be shipped together
                </li>
                <li className={`flex items-center mt-5 text-neutral-500 dark:text-neutral-400`}>
                    <svg className="h-5 w-5 text-primary-6000 me-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    For sending bulky items that can be more securely transported on a pallet
                </li>
                <li className={`flex items-center mt-5 text-neutral-500 dark:text-neutral-400`}>
                    <svg className="h-5 w-5 text-primary-6000 me-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    For business distribution and supply chain needs.
                </li>
                <li className={`flex items-center mt-5 text-neutral-500 dark:text-neutral-400`}>
                    <svg className="h-5 w-5 text-primary-6000 me-2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />  <polyline points="22 4 12 14.01 9 11.01" /></svg>
                    When the load requires special equipment for handling.
                </li>
                </ul>
            </div>
            <div className="flex-grow relative p-12 z-10 p-mb-10">
                <NcImage className="border-4 border-white dark:border-neutral-900 sm:border-[10px] w-full border-mb-0" src={rightImgPng} />
            </div>
    </div>
    </>
  )
}
