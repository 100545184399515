import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TrackFormForm from "components/HeroSearchForm/(car-search-form)/TrackForm";

export interface BannerProps {
    className?: string;
    rightImg?: string;
    heading: ReactNode;
    subHeading?: string;
    btnText: string;
    textClass?: string;
    showTrack?: boolean;
    trackingCode?: string;
    trackingSubmit?: (event: any) => Promise<void>;
}

const Banner: FC<BannerProps> = ({
    className = "",
    rightImg,
    heading,
    subHeading,
    btnText,
    textClass = "",
    showTrack,
    trackingSubmit,
    trackingCode,
}) => {
    return (
        <div className={`nc-Banner relative ${className}`} data-nc-id="Banner">
            <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
                <div className={`w-screen max-w-full ${rightImg ? `xl:max-w-lg` : ``} space-y-5 lg:space-y-7`}>
                    <h2 className={`text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 ${textClass}`} >
                        {heading}
                    </h2>
                    <span className={`block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 ${textClass}`}>
                        {subHeading}
                    </span>
                    {!!btnText && (
                        <ButtonPrimary href="/login">{btnText}</ButtonPrimary>
                    )}

                    {!!showTrack && (
                        <TrackFormForm trackingCode={trackingCode} trackingSubmit={trackingSubmit} />
                    )}
                </div>
                {rightImg ? (
                    <div className="flex-grow">
                        <img className="w-full" src={rightImg} alt="" />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default Banner;
