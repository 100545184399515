import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { toast, ToastContainer } from "react-toastify";
import { Globle } from "hooks/Globle";
import $ from "jquery";
import axios from "axios";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const { apiPath, convertToObject } = Globle();

    const handleErrors = (errors: { [key: string]: string[] }) => {
        Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            errorMessages.forEach((message) => {
                toast.error(message);
            });
        });
    };


    const followSubmit = async (event: any) => {
        event.preventDefault();

        const inputArray = $(event.target).serializeArray();
        const data = convertToObject(inputArray);
        var btn = $(event.target).find('[type="submit"]').html();
        $(event.target).find('[type="submit"]').attr("disabled", "disabled");

        await axios
            .post(apiPath + "api/subscribe-submit", data)
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        error: any;
                        success: boolean;
                        message: string;
                    };

                    if (
                        dataResp.success == false &&
                        typeof dataResp.errors != undefined
                    ) {
                        handleErrors(dataResp.errors);
                    }

                    if (dataResp.success == false && typeof dataResp.error) {
                        toast.error(dataResp.error);
                    }

                    if (dataResp.success == true) {
                        toast.success(dataResp.message);
                        $(event.target).find('input').each(function(k, v){
                            $(v).val('');
                        })
                        $(event.target).find('textarea').each(function(k, v){
                            $(v).val('');
                        })
                    }
                    
                    $(event.target).find('[type="submit"]').removeAttr("disabled");

                } else {
                    toast.error("Something went wrong, Please Try Again!");
                    $(event.target).find('[type="submit"]').removeAttr("disabled");

                }
            })
            .catch((error) => {
                if (error.response.data.errors) {
                    handleErrors(error.response.data.errors);
                }
                $(event.target).find('[type="submit"]').html(btn);
                $(event.target).find('[type="submit"]').removeAttr("disabled");
            });
    };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <ToastContainer />
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discount
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
          </li>
        </ul>
        <form onSubmit={followSubmit} method="POST" className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            name="email"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
