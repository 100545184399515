"use client";

import { FC } from "react";
import LocationInput from "../LocationInput";

export interface TrackFormProps {
  trackingCode?: string
  trackingSubmit?: (event: any) => void;
}

const TrackForm: FC<TrackFormProps> = ({trackingCode, trackingSubmit}) => {


  return (
    <form onSubmit={trackingSubmit} method="POST" className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <div className={`relative flex flex-row`}>
        <LocationInput
          placeHolder="Tracking No."
          desc="Enter your Palletbooking Consignment No. (GL12345678)"
          className="flex-1"
          inputName="track"
          initValue={trackingCode}
        />
        
        <div className="flex items-center pr-2 xl:pr-4">
            {/* <ButtonSubmit href="javascript:void(0);" /> */}
            <button className="h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none">
                <span className="mr-3 md:hidden">Search</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
            </button>
        </div>
      </div>
    </form>
  );
};

export default TrackForm;
