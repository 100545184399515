import React from "react";
import { Link } from "react-router-dom";

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({className}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 `}
    >

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      <img
          className={`block max-h-12 ${className}`}
          src="/images/logo-site.png"
          alt="Logo"
        />
    </Link>
  );
};

export default Logo;
