import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import React, { FC, useState, useEffect } from "react";
import DatePicker from "react-datepicker";

export interface SectionDateRangeProps {
    collectionCode?: string;
    deliveryCode?: string;
    disabledDates?: Date[];
    type?: number;
    onlySaturday?: boolean;
    addDays?: number;
    changeDates: (book: Date, deliver: Date) => void;
    triggerMakeDate: boolean;
    weight: number;
    palletWeight: number;
    greaterThenFivePm: boolean;
}

const SectionDateRange: FC<SectionDateRangeProps> = ({
    collectionCode = "",
    deliveryCode = "",
    disabledDates = [],
    type = 1,
    onlySaturday = false,
    addDays = 2,
    changeDates,
    triggerMakeDate = false,
    weight = 0,
    palletWeight = 0,
    greaterThenFivePm = false,
}) => {

    const [bookingDate, setBookingDate] = useState<Date>(new Date());
    const [deliveryDate, setDeliveryDate] = useState<Date>(new Date());

    const daysOfMonth = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];

    useEffect(() => {
        if(disabledDates && disabledDates.length > 0){
            makeDates(bookingDate);
        }
    }, [disabledDates]);

    useEffect(() => {
        if(addDays != null){
            makeDates(bookingDate);
        }
    }, [addDays]);

    useEffect(() => {
        if(bookingDate && deliveryDate){
            changeDates(new Date(bookingDate), new Date(deliveryDate));
        }
    }, [bookingDate, deliveryDate]);

    useEffect(() => {
        if(bookingDate){
            makeDates(bookingDate);
        }
    }, [triggerMakeDate, onlySaturday])


    const calcWorkingDays = (fromDate: any, days: any) => {
        const newDate: any = new Date(fromDate);
        if (onlySaturday === true) {
            while (true) {
                newDate.setDate(newDate.getDate() + 1);
                if (newDate.getDay() === 6) {
                    return newDate;
                }
            }
        } else {
            let count = 0;
            while (count < days) {
                newDate.setDate(newDate.getDate() + 1);
                if (
                    ![0, 6].includes(newDate.getDay()) &&
                    !disabledDates.some(
                        (disabledDate) =>
                            dateFormat(newDate) ===
                            dateFormat(new Date(disabledDate))
                    )
                ) {
                    count++;
                }
            }
            return newDate;
        }
    };

    const diffInDays = (date1: any, date2: any) => {
        const firstDate: any = new Date(date1);
        const secondDate: any = new Date(date2);
        const diffInMilliseconds = secondDate - firstDate;
        return diffInMilliseconds / (1000 * 60 * 60 * 24);
    };

    const dateFormat = (date: any) => {
        return date.toISOString().split("T")[0];
    };

    const adjustDateIfAfter6PM = (start_date:any) => {
        const now = new Date(start_date);
        if (greaterThenFivePm) {
            now.setDate(now.getDate() + 1);
        }
        return now;
    };

    const makeDates = (start_date: any) => {
        const now:any = new Date(adjustDateIfAfter6PM(start_date));

        const dayOfWeek = daysOfWeek[now.getDay()];
        const month = daysOfMonth[now.getMonth()];
        const date = now.getDate();
        if(isDisabledDate(now) || !isWeekday(now)){
            now.setDate(now.getDate() + 1);
            makeDates(now);
            return false;
        }
        // console.log(now, isWeekday(now));


        const formattedBookingDate: any = `${month} ${date} ${now.getFullYear()}`;
        setBookingDate(formattedBookingDate);

        const delivery = calcWorkingDays(now, addDays);

        const deliverDayOfWeek = daysOfWeek[delivery.getDay()];
        const deliverMonth = daysOfMonth[delivery.getMonth()];
        const deliverDate = delivery.getDate();

        const formattedDeliveryDate: any = `${deliverMonth} ${deliverDate} ${delivery.getFullYear()}`;
        setDeliveryDate(formattedDeliveryDate);

        // if (
        //     diffInDays(formattedBookingDate, formattedDeliveryDate) <= 1 &&
        //     onlySaturday == true &&
        //     type === 1
        // ) {
        //     console.log("Trigger type 2 action");
        // } else if (
        //     diffInDays(formattedBookingDate, formattedDeliveryDate) > 1 &&
        //     onlySaturday == true &&
        //     type === 2
        // ) {
        //     console.log("Trigger type 1 action");
        // }
    };

    const isWeekday = (date: Date) => {
        const day = date.getDay();
        return day != 0 && day != 6;
    };

    const isDisabledDate = (date: Date) => {
        const disableDatesArray = disabledDates.map((disabledDate) =>
            disabledDate instanceof Date ? disabledDate : new Date(disabledDate)
        );
        return disableDatesArray.some(
            (disabledDate) =>
                disabledDate.toDateString() === date.toDateString()
        );
    };

    const onChangeDate = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        makeDates(start);
    };

    const formatDate = (d:any) => d.toISOString().split('T')[0];

    const filterDate = (date: Date) => {    
        const today = new Date();

        today.setDate(today.getDate() - 1);
        const isValid: boolean =
            date >= today && isWeekday(date) && !isDisabledDate(date);

        return isValid;
    };

    const renderSectionCheckIndate = () => {
        return (
            <div className="listingSection__wrap overflow-hidden">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">
                        Collect From{" "}
                        <span style={{ color: "#089ed3" }}>
                            {collectionCode}
                        </span>{" "}
                        Deliver To{" "}
                        <span style={{ color: "#089ed3" }}>{deliveryCode}</span>
                    </h2>
                    {(weight > palletWeight) ? (
                        <div>
                            Your consignment exceeds the maximum weight by <b>{weight - palletWeight}KG</b> for your pallet selection.
                            <span className="block text-red-800 font-semibold">Please amend the weight above or change the number of pallets selected below.</span>
                        </div>
                    ) : (
                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                            Please ensure onsite forklifts for oversized pallets.
                            Forklifts not supplied.
                        </span>
                    )}
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* CONTENT */}

                <div className="">
                    <DatePicker
                        selected={bookingDate}
                        onChange={onChangeDate}
                        startDate={bookingDate ?? new Date("MMM DD")}
                        endDate={deliveryDate ?? new Date("MMM DD")}
                        filterDate={filterDate}
                        selectsRange
                        monthsShown={2}
                        showPopperArrow={false}
                        inline
                        renderCustomHeader={(p) => (
                            <DatePickerCustomHeaderTwoMonth {...p} />
                        )}
                        renderDayContents={(day, date) => (
                            <DatePickerCustomDay dayOfMonth={day} date={date} />
                        )}
                    />
                </div>
            </div>
        );
    };

    return renderSectionCheckIndate();
};

export default SectionDateRange;
