import React, { CSSProperties, InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  badge?: string;
}


const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className = "bg-white", sizeClass = "h-11 px-4 py-3", fontClass = "text-sm font-normal", rounded = "rounded-2xl", children, badge = "", type = "text", ...args}, ref) => {


    const phoneBadgeStyle: CSSProperties = {
      position: 'absolute',
      background: '#f3f4f6',
      padding: '9px 15px',
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      top: '1px',
      left: '1px',
      zIndex:'999'
    };
    
    return (
      <>
        {(badge) ? (
          <span style={phoneBadgeStyle} >{badge}</span>
        ) : ('')}
        <input
          ref={ref}
          type={type}
          style={(badge) ? {paddingLeft:'73px'} : {}} 
          className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
          {...args}
        />
      </>
    );
  }
);

export default Input;
