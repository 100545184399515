"use client";

import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import PalletSelectionInput from "./PalletSelectionInput";
import $ from 'jquery';

export interface PalletSearchFormProps {
  handleSubmit?: (event: any) => void;
}

const PalletSearchForm: FC<PalletSearchFormProps> = ({ handleSubmit }) => {

  const updateParent = (product:any) => {
      $('.products_items').html('');
      if(product){
        product.split(',').forEach((item:any, index:any) => {
            const part = item.split(' x '); 
            $('.products_items').append('<input type="hidden" name="product['+part[1]+']" value="'+part[0]+'" />')
        });
      }
  }

  return (
    <form onSubmit={handleSubmit} method="POST" className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <input type="hidden" name="sessionId" />
      <div className="products_items"></div>
      <div className={`relative flex flex-row`}>
        <LocationInput
          placeHolder="From"
          desc="Collection Postcode"
          inputName="collection_postcode"
          className="flex-1"
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            placeHolder="To"
            desc="Delivery Postcode"
            inputName="delivery_postcode"
            className="flex-1"
            divHideVerticalLineClass="-inset-x-0.5"
          />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <PalletSelectionInput 
            className="flex-1" 
            placeHolder="Pallet Spaces"
            desc="Select Pallets"
            updateParent={updateParent}
          />
        </div>
    </form>
  );
};

export default PalletSearchForm;
