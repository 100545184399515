import React from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Banner from "components/SectionHero/Banner";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

const Page404: React.FC = () => (
    <div className={`nc-PayPage`} data-nc-id="PayPage">
        <div
            className={`nc-PageAbout overflow-hidden relative`}
            data-nc-id="CheckOut"
        >
            <Helmet>
                <title>404 | PalletBooking</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism />

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <Banner
                    textClass="text-center"
                    heading="😖 404 - Page Not Found"
                    btnText=""
                    subHeading="The page you're looking for cannot be found (404 Error). It may have been moved or no longer exists. Please return to the homepage"
                />
            </div>
        </div>

        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
            <SectionSubscribe2 className="" />
        </div>
    </div>
);

export default Page404;
