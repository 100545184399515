import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Globle } from "hooks/Globle";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import axios from "axios";

export interface ContactUsPageProps {
    className?: string;
}

const info = [
    {
        title: "🗺 ADDRESS",
        desc: "Palletbooking Ltd, Unit D, Knaves Beech Way Loudwater, High Wycombe, Buckinghamshire, HP10 9QY, United Kingdom"
    },
    {
        title: "✉️ EMAIL",
        desc: "sales@palletbooking.co.uk"
    },
    {
        title: "☎ PHONE",
        desc: "+44 (0) 1753525959"
    }
];

const ContactUsPage: FC<ContactUsPageProps> = ({ className = "" }) => {
    const { apiPath, convertToObject } = Globle();

    const handleErrors = (errors: { [key: string]: string[] }) => {
        Object.keys(errors).forEach((key) => {
            const errorMessages = errors[key];
            errorMessages.forEach((message) => {
                toast.error(message);
            });
        });
    };


    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const inputArray = $(event.target).serializeArray();
        const data = convertToObject(inputArray);
        var btn = $(event.target).find('[type="submit"]').html();
        $(event.target).find('[type="submit"]').html("Submitting");
        $(event.target).find('[type="submit"]').attr("disabled", "disabled");

        await axios
            .post(apiPath + "api/contact-submit", data)
            .then((response) => {
                if (response.status == 200) {
                    const dataResp = response.data as {
                        errors: any;
                        error: any;
                        success: boolean;
                        message: string;
                    };

                    if (
                        dataResp.success == false &&
                        typeof dataResp.errors != undefined
                    ) {
                        handleErrors(dataResp.errors);
                    }

                    if (dataResp.success == false && typeof dataResp.error) {
                        toast.error(dataResp.error);
                    }

                    if (dataResp.success == true) {
                        toast.success(dataResp.message);
                        $(event.target).find('input').each(function(k, v){
                            $(v).val('');
                        })
                        $(event.target).find('textarea').each(function(k, v){
                            $(v).val('');
                        })
                    }

                    
                    $(event.target).find('[type="submit"]').html(btn);
                    $(event.target).find('[type="submit"]').removeAttr("disabled");

                } else {
                    toast.error("Something went wrong, Please Try Again!");
                    $(event.target).find('[type="submit"]').html(btn);
                    $(event.target).find('[type="submit"]').removeAttr("disabled");

                }
            })
            .catch((error) => {
                if (error.response.data.errors) {
                    handleErrors(error.response.data.errors);
                }
                $(event.target).find('[type="submit"]').html(btn);
                $(event.target).find('[type="submit"]').removeAttr("disabled");
            });
    };

    return (
        <div
            className={`nc-PageContact overflow-hidden ${className}`}
            data-nc-id="PageContact"
        >
            <Helmet>
                <title>Contact | PalletBooking</title>

                <meta property="og:title" content="Get in Touch with Pallet Booking" />
                <meta property="og:description" content="Need assistance or have questions? Reach out to the Pallet Booking team for help with all your pallet delivery needs. We're here to assist you." />
                <meta property="og:image" content="/images/logo-site.png" />
                <meta property="og:url" content="/contact-us" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="PalletBooking" />
                <meta property="og:locale" content="en_US" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Contact Pallet Booking" />
                <meta name="twitter:description" content="We're here to help! Contact us for any inquiries or support related to your pallet delivery services." />
                <meta name="twitter:image" content="/images/logo-site.png" />

            </Helmet>

            <div className="mb-24 lg:mb-32 relative">
                <ToastContainer />
                <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Contact
                </h2>
                <div className="container max-w-7xl mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                        <div className="max-w-sm space-y-8">
                            {info.map((item, index) => (
                                <div key={index}>
                                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                        {item.title}
                                    </h3>
                                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                        {item.desc}
                                    </span>
                                </div>
                            ))}
                            {/* <div>
                                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                    🌏 SOCIALS
                                </h3>
                                <SocialsList className="mt-2" />
                            </div> */}
                        </div>
                        <div>
                            <form
                                className="grid grid-cols-1 gap-6"
                                method="post"
                                onSubmit={handleSubmit}
                            >
                                <label className="block">
                                    <Label>First name</Label>
                                    <Input
                                        placeholder="Jhon"
                                        type="text"
                                        className="mt-1"
                                        name="first_name"
                                    />
                                </label>

                                <label className="block">
                                    <Label>Last name</Label>
                                    <Input
                                        placeholder="Doe"
                                        type="text"
                                        className="mt-1"
                                        name="last_name"
                                    />
                                </label>

                                <label className="block">
                                    <Label>Email address</Label>
                                    <Input
                                        type="email"
                                        placeholder="example@example.com"
                                        className="mt-1"
                                        name="email"
                                    />
                                </label>

                                <label className="block">
                                    <Label>Phone Number</Label>
                                    <Input
                                        type="number"
                                        placeholder="+447712345678"
                                        className="mt-1"
                                        name="phone"
                                    />
                                </label>

                                <label className="block">
                                    <Label>Message</Label>
                                    <Textarea
                                        className="mt-1"
                                        rows={6}
                                        name="message"
                                    />
                                </label>
                                <div>
                                    <ButtonPrimary type="submit">
                                        Send Message
                                    </ButtonPrimary>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* OTHER SECTIONS */}
            <div className="container">
                <div className="relative py-16">
                    <BackgroundSection />
                    <SectionClientSay uniqueClassName="Pagecontact_" />
                </div>
                {/* <SectionSubscribe2 className="py-24 lg:py-32" /> */}
            </div>
        </div>
    );
};

export default ContactUsPage;
