import { useState } from 'react';
import bgImgPng from "images/pallerbanner.jpg";
var sectionStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: "url(" +  bgImgPng + ")"
  };
const FAQ: React.FC = () => {
  const [open, setOpen] = useState<number | null>(null);  // Type for state

  const toggleOpen = (index: number) => {  // Explicitly set the type of 'index' to 'number'
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  };

  const faqData = [
    { question: "What is a pallet delivery service?", answer: "A pallet delivery service is a logistics solution where goods are loaded onto a pallet—a flat, sturdy platform—and transported as a unit. This method is often used for large, heavy, or multiple items, providing an efficient and secure way to move goods." },
    { question: "How do I book a pallet delivery?", answer: "Booking a pallet delivery is simple. You can visit our website, select the type of pallet you need, enter your collection and delivery details, choose your preferred delivery options, and complete the booking online." },
    { question: "What types of pallets can I choose from?", answer: "We offer a range of pallet sizes, including Mini, Quarter, Half, Full, and Oversize pallets. Each size accommodates different types and quantities of goods, ensuring flexibility for your shipping needs." },
    { question: "Can I track my pallet delivery?", answer: "Yes, our service includes detailed tracking. You’ll receive updates on the status of your pallet delivery, including when it’s collected, in transit, and delivered." },
    { question: "What areas do you cover?", answer: "We operate exclusively in the UK, offering nationwide coverage. We also specialize in deliveries to Amazon Fulfillment Centres across the country." },
    { question: "When can my pallet be collected?", answer: "If you book before 11:45 AM on a weekday, your pallet can be collected the same day. We also offer flexible collection options, including weekends and customer choice time delivery." },
    { question: "Do you offer urgent or time-specific deliveries?", answer: "Yes, we provide urgent delivery services as well as options for weekend and customer choice time deliveries, ensuring your pallet arrives exactly when you need it." },
    { question: "Is my shipment insured?", answer: "Currently, we do not offer insurance for goods. We recommend that you arrange your own insurance if needed, particularly for high-value items." },
    { question: "How much does pallet delivery cost?", answer: "The cost varies depending on the size of the pallet, the distance between the collection and delivery points, and any additional services like urgent or weekend delivery. You can get an instant quote on our website." },
    { question: "How should I prepare my goods for pallet delivery?", answer: "Goods should be securely packed and properly stacked on the pallet. Use shrink wrap, straps, or banding to keep items in place. Ensure that the load is stable and does not overhang the edges of the pallet." },
    { question: "What happens if my delivery is delayed?", answer: "While we strive for timely deliveries, unforeseen circumstances can sometimes cause delays. If this occurs, our customer support team will notify you promptly and work to resolve the issue as quickly as possible." },
    { question: "How can I contact customer support?", answer: "Our dedicated support team is available Monday to Friday, 9:00 AM to 5:00 PM, via WhatsApp or LiveChat. You can also reach out to us through our website for any inquiries or assistance." },
    { question: "Are you Amazon-approved?", answer: "Yes, we are trusted by thousands of Amazon sellers and are approved for deliveries to Amazon Fulfillment Centres across the UK." },
  ];

  return (
    <div className="pb-14 flex flex-col items-center justify-center relative" style={sectionStyle}>
      <div className="absolute bg-blue-900 bg-opacity-75 dark:bg-opacity-40 h-full md:bottom-0 top-auto w-screen z-0"></div>
      <div className="container relative">
      <h2 className="text-3xl text-white font-semibold py-16 text-center">FAQ about PalletBooking delivery services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl">
        {faqData.map((faq, index) => (
          <div key={index} className="shadow-lg">
            <button
              className="bg-white rounded-lg  w-full p-4 text-left text-lg font-medium text-gray-800 flex justify-between items-center"
              onClick={() => toggleOpen(index)}
            >
              {faq.question}
              <span className={`transform transition-transform duration-300 ${open === index ? 'rotate-45' : ''}`}>
                +
              </span>
            </button>
            {open === index && (
              <div className="px-4 pb-4 text-gray-600 bg-white" style={{borderBottomRightRadius:'10px', borderBottomLeftRadius:'10px', position:'relative', top:'-5px'}}>
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      </div> 
    </div>
  );
};

export default FAQ;
