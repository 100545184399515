import React, { useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";

export interface StayDatesRangeInputProps {
    className?: string;
    collectionDate?: Date;
    deliveryDate?: Date;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
    className = "flex-1",
    collectionDate = new Date(),
    deliveryDate = new Date()
}) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const daysOfMonth = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];

    const dateFormat = (inpDate: any) => {
        if (inpDate instanceof Date && !isNaN(inpDate.getTime())) {
            const now: any = new Date(inpDate);
            const month = daysOfMonth[now.getMonth()];
            const date = now.getDate();
            const formattedBookingDate: any = `${month} ${date}`;
            return formattedBookingDate;
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (collectionDate) {
            setStartDate(dateFormat(collectionDate));
        }
        if (deliveryDate) {
            setEndDate(dateFormat(deliveryDate));
        }
    }, [collectionDate, deliveryDate]);

    
    const renderInput = () => {
        return (
            <>
                <div className="text-neutral-300 dark:text-neutral-400">
                    <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                </div>
                <div className="flex-grow text-left">
                    <span className="block xl:text-lg font-semibold">
                        {startDate} - {endDate}
                    </span>
                    <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                        {"Collection - Delivery"}
                    </span>
                </div>
            </>
        );
    };

    return (
        <Popover
            className={`StayDatesRangeInput z-10 relative flex ${className}`}
        >
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none`}
                    >
                        {renderInput()}
                    </Popover.Button>
                </>
            )}
        </Popover>
    );
};

export default StayDatesRangeInput;
