import React, { useEffect, FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router-dom';
import $ from 'jquery';

import { Globle } from "hooks/Globle";
import Banner from "components/SectionHero/Banner";
import { ToastContainer, toast } from "react-toastify";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import moment from "moment";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";

const TrackingPage: React.FC = () => {

    const navigate = useNavigate();
    const [status, setStatus] = useState<any>([]);
    const [additional, setAdditional] = useState<any>([]);
    const [booking, setBooking] = useState<any>([]);
    const [totalAdd, setTotalAdd] = useState<number>(0);
    const { apiPath } = Globle();
    const { tracking } = useParams();
    const location = useLocation();

    const handleErrors = (errors: { [key: string]: string[] }) => {
        Object.keys(errors).forEach(key => {
            const errorMessages = errors[key];
                errorMessages.forEach(message => {
                toast.error(message);
            });
        });
    };

    const trackingSubmit = async (event:any) => {
        event.preventDefault();
        let tracking_code = $(event.target).find('[name="track"]').val();
        getOrder(tracking_code);
        navigate(`/tracking/${tracking_code}`);
        // navigate(`/tracking/${tracking_code}`);
    }

    const getOrder = (tracking_code:any = null) => {
        var track = tracking_code ? tracking_code : tracking;
        axios.post(apiPath+'api/get-tracking-code', {'tracking':track}).then(response => {
    
            if(response.status == 200){
                
                const dataResp = response.data as {errors: any;success: boolean, error:any, data:any};

                if(dataResp.success == false && dataResp.errors){
                    handleErrors(dataResp.errors);
                }

                if(dataResp.success == false && dataResp.error){
                    toast.error(dataResp.error);
                }
        
                if(dataResp.success == true){
                    setBooking(dataResp.data.book);
                    setAdditional(dataResp.data.additionals);
                    if(dataResp && dataResp.data && dataResp.data.additionals){
                        const totalPrice = dataResp.data.additionals.reduce((sum:any, item:any) => sum + (item.paid == 0) ? item.price : 0, 0);
                        setTotalAdd(totalPrice);
                    }

                    const dstatus:any = [];
                    const uniqueStatus:any = dataResp.data.status.filter((item:any) => {
                        if(item.status && !dstatus.includes(item.text)){
                            dstatus.push(item.text);
                            return item;
                        }
                    });

                    setStatus(uniqueStatus);
                }
        
            }else{
                toast.error("Something went wrong, Please Try Again!");
            }
    
        }).catch(error => console.error('Error adding user:', error));
    }

    const confirmBooking = (userBookingCheckoutSessionId:string, mode:string) => {
        axios
        .post(apiPath + "api/confirm-booking?session_id=" + userBookingCheckoutSessionId + "&additional=1" + "&mode="+mode)
        .then((response) => {
            if (response.status == 200) {
                const dataResp = response.data as {
                    errors: any;
                    error: any;
                    order_id: any;
                    success: boolean;
                };
  
                if (dataResp.success == false && typeof dataResp.errors != undefined){
                    // navigate("/");
                }
  
                if (dataResp.success == true) {
                  Cookies.set('userBookingOrderId', dataResp.order_id);
                  Cookies.set('userBookingCheckoutSessionId', userBookingCheckoutSessionId);
                  navigate("/thankyou");
                }
  
            } else {
                toast.error("Something went wrong, Please Try Again!");
            }
        })
        .catch((error) => console.error("Error adding user:", error));
    };

    const additionalPayment = (event:any) => {

        var btn = $(event.target).html();
        $(event.target).attr('disabled', 'disabled');
        $(event.target).html('Redirecting...');

        axios.post(apiPath+'api/additional-submittion', {'tracking':tracking}).then(response => {
    
            if(response.status == 200){
                
                const dataResp = response.data as {errors: any;success: boolean, error:any, data:any, redirect:any, order_id:any};

                if(dataResp.success == false && dataResp.errors){
                    handleErrors(dataResp.errors);
                }

                if(dataResp.success == false && dataResp.error){
                    toast.error(dataResp.error);
                }
        
                if(dataResp.success == true){
                    Cookies.set('userBookingRedirectLink', dataResp.order_id);
                    window.location.href = dataResp.redirect;
                }
        
                $(event.target).html(btn);

            }else{
                toast.error("Something went wrong, Please Try Again!");
                $(event.target).html(btn);
            }
    
        }).catch(error => {
            console.error('Error adding user:', error);
            $(event.target).html(btn);
        });
    }

    useEffect(() => {
        getOrder(tracking);
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const userBookingCheckoutSessionId = params.get('session_id');
        const mode:any = params.get('mode');
        
        if (userBookingCheckoutSessionId) {
          confirmBooking(userBookingCheckoutSessionId, mode);
        }
    }, [location.search]);
    
    useEffect(() => {
        // Function to scroll to the section based on hash
        const scrollToHash = () => {
          const hash = window.location.hash; // Get the hash from the URL
          if (hash) {
            var target:any = $(hash);
            if(target && target.offset()){
                $('html, body').animate({
                    scrollTop: target.offset().top - 100
                }, 1000);
            }
          }
        };
    
        // Call the scroll function when the component mounts or when location changes
        if(additional){
            scrollToHash();
        }
    }, [location, additional]);
    

    const renderContent = () => {
        return (
            <>
                <style>
                {` 
                    .example-4 {
                        outline-width: 1px;
                        outline-offset: 0;
                        outline-color: rgba(0, 130, 206, 0.75);
                        outline-style: solid;
                        animation: animateOutline 3s ease infinite;
                    }

                    @keyframes animateOutline {
                        0% {
                            outline-width: 1px;
                            outline-offset: 0;
                            outline-color: rgba(0, 130, 206, 0);
                        }

                        10% {
                            outline-color: rgba(0, 130, 206, 0.75);
                        }

                        /* The animation finishes at 50% */
                        50% {
                            outline-width: 3px;
                            outline-offset: 1px;
                            outline-color: rgba(0, 130, 206, 0);
                        }

                        100% {
                            outline-width: 3px;
                            outline-offset: 1px;
                            outline-color: rgba(102, 102, 102, 0);
                        }
                    }
                `}
                </style>
                {(booking && booking.Id) ? (
                    <>
                    <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
                        <div className="listingSection__wrap">
                            <h2 className="text-2xl font-semibold">
                                Consignment No - {booking.order_no}
                            </h2>

                            <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span>
                                        <span className="font-semibold">Collect Postcode: </span>
                                        {booking.collection_postcode}</span>
                                </div>
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span>
                                        <span className="font-semibold">Deliver Postcode: </span>
                                        {booking.delivery_postcode}</span>
                                </div>
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span>
                                        <span className="font-semibold">Collect Date: </span>
                                        {moment(booking.collection_date).format('Do MMM, YYYY')}</span>
                                </div>
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span>
                                        <span className="font-semibold">Deliver Date: </span>
                                        {moment(booking.delivery_date).format('Do MMM, YYYY')}</span>
                                </div>
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" /> 
                                    <span>
                                        <span className="font-semibold">Deliver Address: </span>
                                         <br />{booking.delivery_info.address_line_1}  <br /> {booking.delivery_info.address_line_2}  <br /> {booking.delivery_info.address_line_3}  <br /> {booking.delivery_info.county}, {booking.delivery_info.town}</span>
                                </div>
                                {(booking.api_id) ? (
                                    <div className="flex items-center space-x-3">
                                        <CheckCircleIcon className="w-5 h-5" /> 
                                        <span>
                                            <span className="font-semibold">Consignment Label: </span>
                                            <a className="text-blue-700" href={`/api/consignment-label/${booking.api_id}`} target="_blank">Download</a>
                                        </span>
                                    </div>
                                ): ('')}
                            </div>
                        </div>

                        {(additional.length > 0) ? (
                        <div className="listingSection__wrap example-4" id="additional">
                            <h2 className="text-2xl font-semibold">
                                Additional Payment
                            </h2>

                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Description
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right">
                                                Price
                                            </th>
                                        </tr>
                                    </thead>
                                    {(additional && additional.length > 0) ? (
                                        <tbody>
                                            {additional.map((item:any, index:number) => {
                                                return (
                                                <tr key={index + 91} className="text-xs text-gray-800">
                                                    <td className="px-6 py-4"> {item.description} 
                                                        <span className={(item.paid == 0) ? 'text-red-600' : 'text-green-600'}> {(item.paid == 0) ? 'Not Paid' : 'Paid'} </span> 
                                                    </td>
                                                    <td className="px-6 py-4 text-right"> £{item.price} </td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    ) : ('')}
                                    
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Total Payable
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right">
                                                £{ totalAdd }
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                            {(totalAdd > 0) ? (
                                <div className="flex justify-between">
                                    <div className="w-2/3 flex justify-start">
                                        <div className="mr-1">* </div>
                                        <p className="text-xs">Please click <b>Pay Now</b> to settle the outstanding balance and ensure your delivery proceeds as scheduled.</p>
                                    </div>
                                    <button onClick={(event) => additionalPayment(event)} className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 w-36">
                                        Pay Now
                                    </button>
                                </div>
                            ) : ('')}

                        </div>
                        ) : ('')}
                        

                        <div className="listingSection__wrap">
                            <h2 className="text-2xl font-semibold">
                                Need any support? 
                            </h2>

                            <Link to={'/contact-us'} className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 ">
                                Contact Us
                            </Link>

                            <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
                                <div className="flex items-center space-x-3">
                                    <CheckCircleIcon className="w-5 h-5" />
                                    <span>We're here to help. Please use our online support to get in touch should you require any assistance.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block flex-grow mt-14 lg:mt-0">
                        <div className="listingSection__wrap lg:shadow-xl">
                            <span className="text-2xl font-semibold block">Tracking Status</span>
                            <div className="mt-8 flex">
                                <div className="flex-shrink-0 flex flex-col items-center py-2">
                                    <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                                    <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                                    <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                                </div>
                                <div className="ml-4 space-y-14 text-sm">
                                    {(status && status.length > 0) ? (
                                    <div className="ml-4 space-y-8 text-sm">
                                        {status.map((item:any, index:number) => {
                                            return(
                                                <div key={+index+8989} className="flex flex-col space-y-2">
                                                    <span className=" text-neutral-500 dark:text-neutral-400">
                                                        {item.status}, {moment(item.created_at).format('MMM, Do - hh:mm a')}
                                                    </span>
                                                    <span className=" font-semibold">
                                                        {item.text}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                ) : (
                    <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                        <h2 className="text-3xl lg:text-4xl font-semibold">
                            ❌ Invalid Consignment Number
                        </h2>
                        <p>The tracking or consignment number you provided doesn't match any records in the system. Please double-check the number for any typos or errors. If the issue persists, contact customer support for further assistance</p>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className={`nc-PayPage`} data-nc-id="PayPage">
            <ToastContainer />
            <div
                className={`nc-PageAbout overflow-hidden relative`}
                data-nc-id="CheckOut"
            >
                <Helmet>
                    <title>Checkout | PalletBooking</title>
                </Helmet>

                {/* ======== BG GLASS ======== */}
                {/* <BgGlassmorphism /> */}

                <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                    <Banner
                        // textClass="text-center"
                        heading="Tracking Pallets"
                        btnText=""
                        showTrack={true}
                        trackingCode={tracking}
                        trackingSubmit={trackingSubmit}
                    />
                </div>
            </div>

            <div className="ListingDetailPage">
                <div className="container ListingDetailPage__content">
                    <div className=" nc-ListingCarDetailPage ">
                        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
                            {renderContent()}
                        </main>
                    </div>
                </div>
            </div>
                
            <div className="container py-6">
                {/* <SectionSubscribe2 className="" /> */}
            </div>
        </div>
    );
};

export default TrackingPage;
