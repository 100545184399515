import React, { useEffect, FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import { Globle } from "hooks/Globle";
import Banner from "components/SectionHero/Banner";
import { toast } from "react-toastify";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

const ThankyouPage: React.FC = () => {

    const navigate = useNavigate();
    const [order, setOrder] = useState<any>([]);
    const { apiPath, convertToObject } = Globle();
    const userBookingSessionId = Cookies.get("userBookingOrderId");

    const getOrder = () => {

        if(userBookingSessionId && userBookingSessionId != undefined){

            axios
                .post(apiPath + "api/order", {'orderId': userBookingSessionId})
                .then((response) => {
                    if (response.status == 200) {
                        const dataResp = response.data as {
                            error: any;
                            errors: any;
                            data: any;
                            success: boolean;
                        };

                        if (
                            dataResp.success == false &&
                            typeof dataResp.errors != undefined
                        ) {
                            navigate("/");
                        }


                        if (dataResp.success == true) {
                            setOrder(dataResp.data);
                        }
                    } else {
                        toast.error("Something went wrong, Please Try Again!");
                    }
                })
                .catch((error) => console.error("Error adding user:", error));

        }else{
            navigate("/");
        }

    }

    useEffect(() => {
        getOrder();
    }, [])

    const renderContent = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold flex justify-between">
                    <div>
                        Booking Confirm 
                    </div>
                    <div>
                        {order.order_no ? order.order_no : null}
                    </div>
                </h2>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">Booking detail</h3>
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <tbody>
                                <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                    <td className="py-4 text-left py-3">Email</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.email ? order.email : null}</td>
                                        
                                    <td className="py-4 text-left py-3">Order No.</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.order_no ? order.order_no : null}</td>
                                </tr>
                            
                                <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                    <td className="py-4 text-left py-3">Collection Postcode</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.collection_postcode ? order.collection_postcode : null}</td>
                                        
                                    <td className="py-4 text-left py-3">Delivery Postcode</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.delivery_postcode ? order.delivery_postcode : null}</td>
                                </tr>
                                
                                <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                    <td className="py-4 text-left py-3">Collection Date</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.collection_date ? moment(order.collection_date).format('Do MMM, YYYY') : null}</td>
                                        
                                    <td className="py-4 text-left py-3">Delivery Date</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.delivery_date ? moment(order.delivery_date).format('Do MMM, YYYY') : null}</td>
                                </tr>

                                <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                    <td className="py-4 text-left py-3">Weight</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">{order.weight ? order.weight : null}kg</td>
                                </tr>

                                <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                    <td className="py-4 text-left py-3">Total</td>
                                    <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">£{order.price ? order.price : null}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {(order.additionals && order.additionals.length) ? (
                        <div>
                            <h3 className="text-2xl font-semibold">Additional</h3>
                            <div className="relative overflow-x-auto sm:rounded-lg">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <tbody>
                                        <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                            <th className="py-4 text-left py-3">Description</th>
                                            <th className="py-4 text-right py-3" align="right">Price</th>
                                        </tr>
                                        {order.additionals.map((add:any) => (
                                            <tr className="text-neutral-6000 dark:text-neutral-300 px-6 py-3">
                                                <td className="py-4 text-left font-medium text-neutral-900 dark:text-neutral-100">
                                                    {add.description} 
                                                    <span className={(add.paid == 0) ? 'text-red-600' : 'text-green-600'}> {(add.paid == 0) ? 'Not Paid' : 'Paid'} </span>
                                                </td>
                                                <td align="right" className="py-4 text-right font-medium text-neutral-900 dark:text-neutral-100">£{add.price ? add.price : null}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : ('')}
                    

                    <Link to={'/tracking/'+order.order_no} className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 "> Track Your Order </Link>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-PayPage`} data-nc-id="PayPage">
            <div
                className={`nc-PageAbout overflow-hidden relative`}
                data-nc-id="CheckOut"
            >
                <Helmet>
                    <title>Checkout | PalletBooking</title>
                </Helmet>

                {/* ======== BG GLASS ======== */}
                {/* <BgGlassmorphism /> */}

                <div className="container py-16 lg:py-28 space-y-6 lg:space-y-8">
                    <Banner
                        textClass="text-center"
                        heading="Thank You"
                        btnText=""
                        subHeading="Thank you for booking a pallet with Pallet Booking. We are thrilled to have you as a customer! Your booking is confirmed, and we’re working to ensure a smooth process. If you have any questions or need further assistance, feel free to reach out. We look forward to serving you again in the future!"
                    />
                </div>

            </div>
            <main className="container mt-11 mb-16 lg:mb-24 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>

            </main>
            
                
            <div className="container py-6 ">
                {/* <SectionSubscribe2 className="" /> */}
            </div>
        </div>
    );
};

export default ThankyouPage;
