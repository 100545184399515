"use client";

import React, { useState, FC, useEffect } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

export interface PalletSelectionBoxProps {
    className?: string;
    products: Pallet[];
    changeOnPallet: any;
}

interface Pallet {
    product_id: number;
    product_name: string;
    oversize: number;
    weight: number;
    api_product_code: string;
    description_line1: string;
    description_line2: string;
    status: boolean;
    qty?: number;
}

const PalletSelectionBox: FC<PalletSelectionBoxProps> = ({
    className = "nc-flex-1.5",
    products = [],
    changeOnPallet,
}) => {
    

    const [Pallets, setPallets] = useState<Pallet[]>([]);

    
    useEffect(() => {
        setPallets(products)
    }, [products]);

    const updatePallet = (id: number) => {
        const cPallets = Pallets.map((item:any) =>
            item.product_id === id
                ? { ...item, qty: +(item.qty || 0) + 1 }
                : item
        )
        setPallets(cPallets);
        changeOnPallet(cPallets);
    };

    const removePallet = (id: number) => {
        const cPallets = Pallets.map((item:any) =>
                item.product_id === id
                    ? { ...item, qty: Math.max((item.qty || 0) - 1, 0) }
                    : item
            )
        setPallets(cPallets);
        changeOnPallet(cPallets);
    };

    const palletsDetails = () => {
        return (
            <>
                <div className="mt-2 grid grid-cols-2 sm:grid-cols-3 gap-4 ">
                    {Pallets && Array.isArray(Pallets) && Pallets.length > 0 ? (
                        Pallets.map((item) => (
                            <div key={item.product_id} className="p-4 flex flex-col items-center">
                                <div className="flex flex-col justify-center items-center w-full">
                                    <img
                                        src={`/images/${(item.product_name) ? item.product_name.replace(' Oversize','').toLowerCase() : ''}-pallet.png`}
                                        alt="Mini Pallet"
                                        className="w-20 h-20 "
                                    />
                                    <div className="flex flex-col justify-center">
                                        <h3 className="text-lg font-semibold">
                                            {item.product_name}
                                        </h3>
                                    </div>
                                    <div className="flex items-center justify-center w-full mt-2">
                                        <div className="flex items-center px-2 py-1 palletButtonBox">
                                            <input type="hidden" name={`product[${item.api_product_code}]`}  value={item.qty ? item.qty : 0} data-code={item.api_product_code} />
                                            <MinusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-8 lg:h-8" onClick={() => removePallet(item.product_id)} />
                                            <span className="px-4 numCount">{item.qty ? item.qty : 0}</span>
                                            <PlusCircleIcon className="cursor-pointer text-neutral-300 dark:text-neutral-400 w-5 h-5 lg:w-8 lg:h-8" onClick={() => updatePallet(item.product_id)} />
                                        </div>
                                    </div>
                                    
                                    <p className="text-sm text-gray-500">
                                        {item.description_line1}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        {item.description_line2}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Up to {item.weight}kg
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No pallets available</p> // Optional: display a message when there are no items
                    )}
                </div>
            </>
        );
    };

    return (
        <div className={`${className}`}>
            {palletsDetails()}
        </div>
    );
};

export default PalletSelectionBox;
